/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { Link } from "gatsby"
import { SEO, Wrapper } from "components"

const NotFoundPage = () => {
  const theme = useTheme()
  const styles = css`
    background: ${theme.color.darkpurple};
    .W {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      text-align: center;
      min-height: 100vh;

      a {
        color: white;
        background: ${theme.color.red};
        font-family: "Product Sans", sans-serif;
        padding: ${theme.padding[200]} ${theme.padding[300]};
        line-height: 1;
        text-decoration: none;
      }
    }
  `

  return (
    <div css={styles}>
      <Wrapper>
        <div className="content">
          <SEO title="404: Not found" />
          <h1>404: NOT FOUND</h1>
          <br />
          <Link to="/">Back to homepage &rarr;</Link>
        </div>
      </Wrapper>
    </div>
  )
}
export default NotFoundPage
